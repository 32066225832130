import * as React from "react";
import { useParams } from "react-router-dom";
import Header from "../components/user/header"
import LeftSidebar from "../components/user/LeftSidebar";
import { Container , Col , Row, Stack, Offcanvas, Button, ToastContainer, Toast, Spinner} from "react-bootstrap";
import WelcomeBanner from "../components/user/WelcomeBanner";
import SearchShieldID from "../components/user/SearchShieldID";
import InvitationTable from "../components/user/Invitations";
import { List } from 'react-bootstrap-icons';
import MyNetwork from "../components/user/MyNetwork";
import Profile from "../components/user/profile/ProfileInfo.js";
import PasswordAndSecurity from "../components/user/profile/PasswordAndSecurity";
import PrivacyAndSharing from "../components/user/profile/PrivacyAndSharing";
import BGCheckApplicationModal from "../components/user/profile/BGCheckApplicationModal";
import MyBackgroundChecks from "../components/user/MyBackgroundChecks";
import { useAuth } from "../components/Auth";
import AddtoNetworkModal from "../components/user/AddtoNetworkModal.js";
import AcceptNewTerms from "../components/user/AcceptNewTerms.js";
import UserEnterpriseDashboardEmbed from "../components/user/enterprise/UserEnterpriseDashboardEmbed.js";
import PurchaseBackgroundCheck from "../components/user/PurchaseBackgroundCheck.js";
import PurchaseWorkflow from "../components/user/PurchaseWorkflow.js";
import IDVerifyWorkflow from '../components/user/IDVerifyWorkflow.js';
import Purchases from "../components/user/profile/Purchases.js";
import Alerts from "../components/user/Alerts.js";


function User() {

	let params = useParams();
	let auth = useAuth();
	const [show, setShow] = React.useState(false);

	const [ saveinprogress , setSaveInProgress ] = React.useState(false);
	const [ saveevents , setSaveEvents ] = React.useState({});

	function handleClose()
	{
		setShow(false);
	}
	function handleShow()
	{
		setShow(true);
	}

	function SaveEvent( component , event )
	{
		let newsaveevents = {...saveevents};

		if(event === 'startsave')
		{
			newsaveevents[component] = event;
		}
		else if(event === 'endsave')
		{
			delete newsaveevents[component];
		}

		if(Object.keys(newsaveevents).length > 0)
		{
			setSaveInProgress(true);
		}
		else
		{
			setTimeout(() => {
				setSaveInProgress(false);
			}, 2000);
		}

		setSaveEvents(newsaveevents);

	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		if(window.clarity)
		{
			window.clarity('set','userid',auth?.user?.userid);
		}

	},[params.panel]);
	/* eslint-enablereact-hooks/exhaustive-deps */

	return (

		<>
			<Header />
			<Button className="mobile-menu d-block d-md-none" variant="link" onClick={handleShow}>
				<List/>
			</Button>
			<Container fluid style={{backgroundColor:"#e5e5e5",height:"100%"}}>
				<Row>
					<Col md={1} lg={2} className="l-sidebar">
						<Offcanvas show={show} onHide={handleClose} responsive="md">
							<Offcanvas.Header closeButton />
							<Offcanvas.Body>
								<LeftSidebar onClick={handleClose}/>
							</Offcanvas.Body>
						</Offcanvas>
					</Col>
					<Col md={11} lg={10}>
						{params.panel === undefined &&
							<Stack className="gap-3 pt-3 pb-3 ps-1">
								<SearchShieldID />
								{ auth.enterpriseprofile && auth.enterpriseprofile.dashboard_enabled && auth.enterpriseprofile.dashboard_enabled === true ? <>
									<UserEnterpriseDashboardEmbed />
								</> : ( <>
									{ (!(auth?.user?.applicationcount) || auth?.user?.applicationcount === '0' ) && <>
										<WelcomeBanner/>
										<PurchaseBackgroundCheck />
									</>}
									{( parseInt(auth?.user?.applicationcount) > 0) && <>
										<MyBackgroundChecks />
									</>}
									<InvitationTable />
								</> )}
							</Stack>}
						{params.panel === "profile" &&
							<>
								<Stack className="gap-3 pt-3 pb-3 ps-1">
									<Profile SaveEvent={SaveEvent}/>
									<PasswordAndSecurity SaveEvent={SaveEvent}/>
									<PrivacyAndSharing SaveEvent={SaveEvent}/>
									<Purchases />
								</Stack>
								<ToastContainer position='bottom-center' style={{zIndex:1000}} className="position-fixed p-4">
									<Toast bg='primary' show={saveinprogress}>
										<Toast.Body style={{textAlign:'center',color:'#FFFFFF',fontWeight:'bold'}}>Saving Profile&nbsp;&nbsp;&nbsp;&nbsp;<Spinner as="span" size="sm" animation="border" />&nbsp;&nbsp;</Toast.Body>
									</Toast>
								</ToastContainer>
							</>}
						{params.panel === "network" && 
							<Stack className="gap-3 pt-3 pb-3 ps-1">
								<InvitationTable />
								<MyNetwork />
							</Stack>}
						{params.panel === 'purchase' && 
							<PurchaseWorkflow />
						}
						{params.panel === 'idverify' && 
							<IDVerifyWorkflow />
						}
						{params.panel === 'alerts' && 
							<Alerts />
						}
					</Col>
				</Row>
			</Container>
			<BGCheckApplicationModal />
			<AddtoNetworkModal />
			<AcceptNewTerms show={ ( auth && auth.user && auth.user.accepted_current_tc === 'false' ) }/>
		</>
	);

}

export default User