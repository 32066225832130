import axios from "axios";

const base_api_endpoint = process.env.REACT_APP_API_ENDPOINT;

async function searchusers( searchterm )
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.post(base_api_endpoint+"/admin/usersearch",{ searchterm },{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.results = response.data.results;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;	
}

async function getuser( userid )
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.post(base_api_endpoint+"/admin/getuser",{ userid },{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.profile = response.data.profile;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;	

}

async function saveuser( profile )
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.post(base_api_endpoint+"/admin/saveuser",{ profile },{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;	


}

async function getbgchecks( userid )
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.post(base_api_endpoint+"/admin/getuserbgchecks",{ userid },{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.bgchecks = response.data.applications;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;	
}

async function getcurrentscore( userid )
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.post(base_api_endpoint+"/admin/getcurrentscore",{ userid },{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.profile = response.data.profile;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;	
}

async function getadminnotes( userid )
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.post(base_api_endpoint+"/admin/getadminnotes",{ userid },{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.notes = response.data.notes;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;	

}

async function saveadminnote( userid , note )
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.post(base_api_endpoint+"/admin/saveadminnote",{ userid , note },{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;	

}

async function gettermshistory( userid )
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.post(base_api_endpoint+"/admin/gettermshistory",{ userid },{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.historyitems = response.data.historyitems;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;	

}

async function sendforgotpassword( userid )
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.post(base_api_endpoint+"/admin/sendforgotpassword",{ userid },{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;	


}

async function getdashboardurl()
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.get(base_api_endpoint+"/admin/dashboardembed",{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.dashboardurl = response.data.dashboard;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;	


}

async function getlegacyaccountlist()
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.get(base_api_endpoint+"/admin/legacyaccountlist",{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.accountlist = response.data.accountlist;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;	

}
async function resettwofactor( userid )
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.post(base_api_endpoint+"/admin/resettwofactor",{ userid },{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;	

}

async function getmonitoringactions( userid )
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.post(base_api_endpoint+"/admin/getmonitoringactions",{ userid },{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.actions = response.data.actions;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;	
}
async function checkirecord( userid )
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.post(base_api_endpoint+"/admin/checkirecord", { userid }, {withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;
}

async function submitrefund( request )
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.post(base_api_endpoint+"/admin/submitrefund",request,{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;
}

async function getReportDownloadLink( appid )
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.post(base_api_endpoint+"/admin/getreportdownloadlink",{appid},{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.downloadlink = response.data.downloadlink;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;			
}

async function downloadreceipt( purchaseid , appid )
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.post(base_api_endpoint+"/admin/downloadreceipt",{ purchaseid , appid },{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.receipt_body = response.data.receipt_body;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;
}

var methods = { 
	searchusers,
	getuser,
	saveuser,
	getbgchecks,
	getcurrentscore,
	getadminnotes,
	saveadminnote,
	gettermshistory,
	sendforgotpassword,
	getdashboardurl,
	resettwofactor,
	getlegacyaccountlist,
	getmonitoringactions,
	submitrefund,
	getReportDownloadLink,
	downloadreceipt,
	checkirecord,
};

export default methods;